class AppUrl {
    static BaseUrl='https://devapi.webhunter24.com/';
    static Slider=this.BaseUrl+'/Slider';
    static Banner=this.BaseUrl+'/Banner';
    static Services=this.BaseUrl+'/Services';
    static Categories=this.BaseUrl+'/Categories';

    static Project8=this.BaseUrl+'/Project8';
    static ProjectAll=this.BaseUrl+'/All-Projects';
    static ProjectDetails=this.BaseUrl+'/ProjectDetails/';
    static About=this.BaseUrl+'/About';
    static SettingData=this.BaseUrl+'/SettingData';
    static ContactSend=this.BaseUrl+'/ContactSend/';
    static CommentSend=this.BaseUrl+'/CommentSend';
    static GetComments=this.BaseUrl+'/GetComments/';

    static  ProjectListByCategory(Category){
        return this.BaseUrl+"/ProjectListByCategory/"+Category;
    };

    static  ProjectListBySearch(SearchKey){
        return this.BaseUrl+"/ProjectListBySearch/"+SearchKey;
    };



}
export default AppUrl

