import React, { Component,Fragment } from 'react';
import {FormControl,InputGroup,ListGroup,Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import Sidebar from "../Sidebar/Sidebar";
import ReactDOM from "react-dom";
import Pagination from "react-js-pagination";


class ProjectListByCategory extends Component {
    constructor(props){
      super();
        this.state={
            Category:props.Category,
            ProjectData:[],
            activePage: 1,
            itemsCountPerPage:1,
            totalItemsCount:1,
            pageRangeDisplayed:3,
            loading:true,
            error:false
        }
        this.handlePageChange=this.handlePageChange.bind(this)
    }

handlePageChange(pageNumber) {
    axios.get('https://devapi.webhunter24.com/ProjectListByCategory/Completed?page='+pageNumber).then(response=> {
      this.setState({
          ProjectData:response.data.data,
          itemsCountPerPage:response.data.per_page,
          totalItemsCount:response.data.total,
          activePage: response.data.current_page
        });
      });
    }

    componentDidMount() {
      window.scroll(0,0)
      axios.get(ApiUrl.ProjectListByCategory(this.state.Category)).then(response=> {
          if(response==null){
              this.setState({ error:true,loading:false})
          }
          else {
              this.setState({
                ProjectData:response.data.data,
                itemsCountPerPage:response.data.per_page,
                totalItemsCount:response.data.total,
                activePage: response.data.current_page,
                loading:false
              })
          }
      }).catch(error=>{
          this.setState({ error:true,loading:false})
      })

    }

  render() {

    if(this.state.loading==true && this.state.error==false ){
        return  <Loading/>
    }
    else if(this.state.loading==false  && this.state.error==false)  {
    const MyList=this.state.ProjectData;
    const MyView=MyList.map((ProjectList,i)=>{
        return <Card className="mb-5">
                  <Row>
                      <Col lg={4} md={4} sm={4} xs={12}>
                          <Card.Img  src={ProjectList.img2} className="catListImg"/>
                      </Col>
                      <Col lg={8} md={8} sm={8} xs={12}>
                        <Card.Body className="p-0">
                          <Card.Title>{ProjectList.title}</Card.Title>
                            <Card.Text>
                                {ProjectList.subtitle}
                            </Card.Text>
                            <Link to={"/ProjectDetails/"+ProjectList.id+"/"+ProjectList.title}>
                              <Button variant="primary">{ProjectList.title}</Button>
                            </Link>
                        </Card.Body>
                      </Col>
                  </Row>
                </Card>
            })


    return (
      <Fragment>
        <Container className="spaceBetween">
          <Row>
            <Col lg={9} md={9} sm={9} xs={12}>
                {MyView}
            </Col>

            <Col lg={3} md={3} sm={3} xs={12}>
               <Sidebar/>
            </Col>
          </Row>
        </Container>
      <div class="d-flex justify-content-center">
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={this.state.itemsCountPerPage}
          totalItemsCount={this.state.totalItemsCount}
          pageRangeDisplayed={this.state.pageRangeDisplayed}
          itemClass="page-item"
          linkClass="page-link"
          onChange={this.handlePageChange}
        />
      </div>
      </Fragment>
            );
        }
        else if(this.state.error==true){
            return  <WentWrong/>
        }

    }
}


export default ProjectListByCategory