import React, { Component,Fragment } from 'react';
import {Container,Row,Col,Button} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import {Link} from "react-router-dom";
import RestClient from "../../RestApi/RestClient";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';

class Services extends Component {
  constructor() {
    super();
    this.state={
      myData:[],
      loading:true,
      error:false
    }
  }

  componentDidMount() {
    RestClient.GetRequest(ApiUrl.Services).then(result=>{
      if(result==null){
        this.setState({ error:true,loading:false})
      }else{
        this.setState({myData:result,loading:false})
      }
    }).catch(error=>{
      this.setState({ error:true,loading:false})
    })

  }

  render() {

    if(this.state.loading==true && this.state.error==false ){
      return  <Loading/>
    }
    else if(this.state.loading==false  && this.state.error==false)  {
      const  myList=this.state.myData;
      const myView=myList.map(myList=>{
        return <Col lg={3} md={3} sm={6} xs={12}>
                  <div className="serviceBox">
                    <img src={myList.service_img}  className="serviceImg" />
                    <div className="serviceContainer">
                      <h6 className="serviceTitle">{myList.service_name}</h6>
                      <p className="serviceSubTitle">{ ReactHtmlParser(myList.service_des) }</p>
                    </div>
                  </div>
               </Col>
             })

    return (
      <Fragment>
        <Container className="spaceBetween">
          <h2 className="mainTitle">Our Services</h2><hr/>
          <Row>
            {myView}
          </Row>
        </Container>
      </Fragment>
    )
  }
      else if(this.state.error==true){
      return  <WentWrong/>
    }
  }
}

export default Services