import React, { Component,Fragment } from 'react';
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import {Link} from "react-router-dom";
import RestClient from "../../RestApi/RestClient";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';

class Footer extends Component {
      constructor() {
        super();
        this.state = {
            company_logo:"",
            company_name:"",
            company_address: "",
            email:"",
            phone_one:"",
            phone_two:"",
            facebook:"",
            youtube:"",
            about:"",
            about_img:"",
            refund:"",
            terms:"",
            privacy:"",
            loaderClass:"text-center",
            mainDivClass:"d-none",
            error:false
        }
    }
    componentDidMount() {
        RestClient.GetRequest(ApiUrl.SettingData).then(result=>{
            if(result==null){
                this.setState({ error:true})
            }
            else{
                this.setState({
                    company_logo:result[0]['company_logo'],
                    company_name:result[0]['company_name'],
                    company_address:result[0]['company_address'],
                    email:result[0]['email'],
                    phone_one:result[0]['phone_one'],
                    phone_two:result[0]['phone_two'],
                    facebook:result[0]['facebook'],
                    youtube:result[0]['youtube'],
                    about:result[0]['about'],
                    about_img:result[0]['about_img'],
                    refund:result[0]['refund'],
                    terms:result[0]['terms'],
                    privacy:result[0]['privacy'],
                    loaderClass:"d-none",
                    mainDivClass:"p-2 text-justify"
                })
            }
        }).catch(error=>{
            this.setState({ error:true})
        })
    }


  render() {
    if(this.state.error==false){
    return (
      <Fragment>
        <Container fluid={true} className="text-center p-4 footerBox">
         <Row>
           <Col lg={3} md={6} sm={12} >
              <h1 className="footerTitle">About Us</h1>
              <p className="footerDes">{ ReactHtmlParser(this.state.about) }</p>
           </Col>

           <Col lg={3} md={6} sm={12}>
              <h1 className="footerTitle">Address</h1>
              <p className="footerDes">{ this.state.company_address }</p>
              <p className="footerDes">{ this.state.email }</p>
              <p className="footerDes">{ this.state.phone_one }, { this.state.phone_two }</p>
           </Col>

           <Col lg={3} md={6} sm={12}>
              <h1 className="footerTitle">Quick Look</h1>
              <Link className="footerLink" to="/ContactSend">Contact Us</Link><br/>
              <Link className="footerLink" to="/ContactSend">About Us</Link>

           </Col>

           <Col lg={3} md={6} sm={12}>
              <h1 className="footerTitle">Legal</h1>
              <Link className="footerLink" to="/Refund">Refund Policy</Link><br/>
              <Link className="footerLink" to="/Privacy">Privacy Policy</Link><br/>
              <Link className="footerLink" to="/Term">Terms & Conditions</Link><br/>
           </Col>
         </Row>
        </Container>

        <Container fluid={true} className="text-center copyrightSection">
            <Link className="copyrightLink" href="#">site.webhunter24.com © 2019-2020</Link>
        </Container>
      </Fragment>
    )
  }

  else if(this.state.error==true){
      return <WentWrong/>
    }

  }
}



export default Footer

