import React, {Component, Fragment} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import validation from "../../validation/validation";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import {Redirect} from "react-router";

class CommentSend extends Component {

        constructor(props) {
        super();
        this.state={
            title:props.ProjectName,
            projectID:props.ProjectID,
            name:"",
            email:"",
            mobile:"",
            msg:"",
        }
    }


    nameOnChange=(event)=>{
      let name=  event.target.value;
      this.setState({name:name})
    }

    emailOnChange=(event)=>{
      let email=  event.target.value;
      this.setState({email:email})
    }

    mobileOnChange=(event)=>{
        let mobile=  event.target.value;
        this.setState({mobile:mobile})
    }
    msgOnChange=(event)=>{
        let msg=  event.target.value;
        this.setState({msg:msg})
    }


    onFormSubmit=(event)=>{
        let title=this.state.title;
        let projectID=this.state.projectID;
        let name=this.state.name;
        let email=this.state.email;
        let mobile=this.state.mobile;
        let msg=this.state.msg;
        let sendBtn=document.getElementById('sendBtn');
        let contactForm=document.getElementById('contactForm');

        if(name.length==0){
            toast.error("Name Required",{
                position:"bottom-center"
            });
        }

        else if(email.length==0){
            toast.error("E-mail Required",{
                position:"bottom-center"
            });
        }

        else if(mobile.length==0){
            toast.error("Mobile Number Required",{
                position:"bottom-center"
            });
        }

        else if(!(validation.EmailRegx).test(email)){
            toast.error("Invalid Email",{
                position:"bottom-center"
            });
        }

        else if(!(validation.NameRegx).test(name)){
            toast.error("Invalid Name",{
                position:"bottom-center"
            });
        }

        else if(!(validation.MobileRegx).test(mobile)){
            toast.error("Invalid Mobile Number",{
                position:"bottom-center"
            });
        }
        else if(msg.length==0){
            toast.error("Please Write Your Message",{
                position:"bottom-center"
            });
        }
        else{
            sendBtn.innerHTML="Sending...";
            let MyFormData=new FormData();
            MyFormData.append("name",name)
            MyFormData.append("email",email)
            MyFormData.append("mobile",mobile)
            MyFormData.append("message",msg)
            MyFormData.append("projectID",projectID)
            MyFormData.append("title",title)

            axios.post(ApiUrl.CommentSend,MyFormData).then(function (response) {
                if(response.status==200 && response.data==1){
                    toast.success("Request Success",{
                        position:"bottom-center"
                    });
                    sendBtn.innerHTML="Send";
                    contactForm.reset();  
                }
                else{
                    toast.error("Request Fail ! Try Again",{
                        position:"bottom-center"
                    });
                    sendBtn.innerHTML="Send"
                }
            }).catch(function (error) {
                toast.error("Request Fail ! Try Again",{
                    position:"bottom-center"
                });
                sendBtn.innerHTML="Send"
            })
        }


        event.preventDefault();
    }


    render() {

        return (
            <Fragment>
                <Container className="spaceBetween">
                    <Form id="contactForm" onSubmit={this.onFormSubmit} className="onboardForm">
                      <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>Name</Form.Label>
                          <Form.Control type="text" id="name"  onChange={this.nameOnChange} placeholder="Enter Name" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="email" id="email" onChange={this.emailOnChange} placeholder="Enter email" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control type="text" id="phone" onChange={this.mobileOnChange} placeholder="Enter Phone" />
                        </Form.Group>
                      </Form.Row>
                     
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Your Comments</Form.Label>
                        <Form.Control as="textarea" onChange={this.msgOnChange} id="msg" rows={3} />
                      </Form.Group>

                      <Button id="sendBtn" type="submit" variant="primary" >
                          Send
                      </Button>
                    </Form>
                    <ToastContainer />
      
                </Container>

            </Fragment>
        );
    }
}

export default CommentSend;