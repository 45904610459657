import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Banner from "../components/TopBanner/TopBanner";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";

class ContactPage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation/>
				<Banner/>
				<Contact/>
				<Footer/>
            </Fragment>
        );
    }
}

export default ContactPage;