import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Banner from "../components/TopBanner/TopBanner";
import ProjectDetails from "../components/Projects/ProjectDetails";
import Footer from "../components/Footer/Footer";

class ProjectDetailsPage extends Component {


    constructor({match}) {
        super();
        this.state={
            projectPassedID:match.params.projectID,
            projectPassedName:match.params.projectName
        }
    }

    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <Banner/>
                <ProjectDetails id={this.state.projectPassedID} projectName={this.state.projectPassedName}/>
                <Footer/>
            </Fragment>
        );
    }
}

export default ProjectDetailsPage;