import React, {Component,Fragment} from 'react';
import ApiUrl from "../RestApi/AppUrl";
import axios from 'axios';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Banner from "../components/TopBanner/TopBanner";
import ProjectListBySearch from "../components/Projects/ProjectListBySearch";
import Footer from "../components/Footer/Footer";



class SearchPage extends Component {

    constructor({match}) {
        super();
        this.state={
            SearchKey:match.params.SearchKey,
            ProjectData:[]
        }

    }


    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <Banner/>
                <ProjectListBySearch SearchKey={this.state.SearchKey} />
                <Footer/>
            </Fragment>
        );
    }
}

export default SearchPage;