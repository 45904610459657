import React, { Component,Fragment } from 'react';
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import whiteLogo from '../../asset/images/navlogo.svg'
import blueLogo from '../../asset/images/navlogoScroll.svg'
import '../../asset/css/custom.css'
import '../../asset/css/bootstrap.min.css'
import '../../asset/css/responsive.css'
import {NavLink} from "react-router-dom";
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';

class TopNavigation extends Component {
    constructor(props){
        super();
        this.state={
            navBarTitle:"navTitle",
            navBarLogo:[whiteLogo],
            navVariant:"dark",
            navBarBack:"navBackground",
            navBarItem:"navItem",
            pageTitle:props.title,
            MenuCatData:[],
            company_name:""
        }
    }

    onScroll=()=>{
        if(window.scrollY>100){
            this.setState({navVariant:'light',navBarTitle:'navTitleScroll', navBarLogo:[blueLogo], navBarBack:'navBackgroundScroll',navBarItem:'navItemScroll'})
        }
        else if(window.scrollY<100){
            this.setState({navVariant:'dark' ,navBarTitle:'navTitle',navBarLogo:[whiteLogo],navBarBack:'navBackground',navBarItem:'navItem'})
        }
    }

    componentDidMount() {
      window.addEventListener('scroll',this.onScroll)

      axios.get(ApiUrl.Categories).then(response=> {
        this.setState({MenuCatData:response.data})
      }).catch(error=> {

      });

      axios.get(ApiUrl.SettingData).then(result=> {
        this.setState({company_name:result[0]['company_name']})
      }).catch(error=> {

      });

    }

  render() {
    const MyList=this.state.MenuCatData;
    const MyView=MyList.map((CategoryList,i)=>{
         return <div><NavDropdown.Item><Link className="footerLink" to={"/ProjectListByCategory/"+CategoryList.cat_name}>{CategoryList.cat_name}
                     </Link></NavDropdown.Item>
                    <NavDropdown.Divider />
                </div>
               })
    return (

      <Fragment>
            <Navbar collapseOnSelect expand="lg" fixed="top" className={this.state.navBarBack}  variant={this.state.navVariant}>
        <Navbar.Brand><NavLink to="/" className={this.state.navBarTitle}><img src={this.state.navBarLogo}/></NavLink>{ this.state.company_name }</Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">

                <Nav className="mr-auto">
                </Nav>

                <Nav>
                  <Nav.Link><NavLink exact activeStyle={{color:'#00a8ee'}} className={this.state.navBarItem} to="/">HOME</NavLink></Nav.Link>
                  <Nav.Link><NavLink exact activeStyle={{color:'#00a8ee'}} className={this.state.navBarItem} to="/All-Projects">PROJECT</NavLink></Nav.Link>
                  <Nav.Link><NavLink exact activeStyle={{color:'#00a8ee'}} className={this.state.navBarItem} to="/About">ABOUT US</NavLink></Nav.Link>

                  <NavDropdown title="PROJECTS" id="collasible-nav-dropdown" className={this.state.title}>
                     {MyView}
                   
                  </NavDropdown>

                  <Nav.Link><NavLink exact activeStyle={{color:'#00a8ee'}} className={this.state.navBarItem} to="/ContactSend" >CONTACT</NavLink></Nav.Link>
                </Nav>

              </Navbar.Collapse>
            </Navbar>
      </Fragment>
    )
  }
}

export default TopNavigation