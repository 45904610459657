import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Banner from "../components/TopBanner/TopBanner";
import ProjectListByCategory from "../components/Projects/ProjectListByCategory";
import Footer from "../components/Footer/Footer";



class ProjectListByCategoryPage extends Component {

    constructor({match}) {
        super();
        this.state={
            Category:match.params.Category
        }

    }

    componentDidMount() {
        window.scroll(0,0)
    }
    
    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <Banner/>
                <ProjectListByCategory Category={this.state.Category}/>
                <Footer/>
            </Fragment>
        );
    }
}

export default ProjectListByCategoryPage;