import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Banner from "../components/TopBanner/TopBanner";
import About from "../components/About/About";
import Footer from "../components/Footer/Footer";

class AboutPage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation/>
				<Banner/>
				<About/>
				<Footer/>
            </Fragment>
        );
    }
}

export default AboutPage;