import React, {Component,Fragment} from 'react';
import {Route,Switch} from "react-router-dom";
import HomePage from "../pages/HomePage";
import ProjectDetailsPage from "../pages/ProjectDetailsPage";
import ProjectListByCategoryPage from "../pages/ProjectListByCategoryPage";
import AllProjectPage from "../pages/AllProjectPage";
import ContactPage from "../pages/ContactPage";
import AboutPage from "../pages/AboutPage";
import RefundPage from "../pages/RefundPage";
import PrivacyPage from "../pages/PrivacyPage";
import TermPage from "../pages/TermPage";
import SearchPage from "../pages/SearchPage";

class AppRoute extends Component {
    render() {
        return (
            <Fragment>
            <Switch>
            <Route exact path="/" component={HomePage}/>

            <Route exact path="/All-Projects"  render={(props) =><AllProjectPage{...props} key={Date.now()}/>}/>
            <Route exact path="/ProjectDetails/:projectID/:projectName"  render={(props) =><ProjectDetailsPage{...props} key={Date.now()}/>}/>
            <Route exact path="/ProjectListByCategory/:Category"  render={(props) =><ProjectListByCategoryPage{...props} key={Date.now()}/>}/>
            <Route exact path="/About"  render={(props) =><AboutPage{...props} key={Date.now()}/>}/>
            <Route exact path="/ContactSend"  render={(props) =><ContactPage{...props} key={Date.now()}/>}/>
            <Route exact path="/Refund"  render={(props) =><RefundPage{...props} key={Date.now()}/>}/>
            <Route exact path="/Privacy"  render={(props) =><PrivacyPage{...props} key={Date.now()}/>}/>
            <Route exact path="/Term"  render={(props) =><TermPage{...props} key={Date.now()}/>}/>
            <Route exact path="/ProjectListBySearch/:SearchKey" render={(props) => <SearchPage {...props} key={Date.now()}/>}/>
            


            </Switch>
            </Fragment>
            );
    }
}

export default AppRoute;