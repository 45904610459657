import React, { Component,Fragment } from 'react';
import {Form,FormControl,InputGroup,ListGroup,Card,Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import ReactHtmlParser from "react-html-parser";
import RestClient from "../../RestApi/RestClient";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import {Redirect} from "react-router";

class Sidebar extends Component {
      constructor(props) {
        super();
        this.state={
            CatData:[],
            SearchKey:"",
            SearchRedirectStatus: false,
        }

        this.SearchOnChange=this.SearchOnChange.bind(this);
        this.SearchOnClick=this.SearchOnClick.bind(this);
        this.searchRedirect=this.searchRedirect.bind(this);
    }

    SearchOnChange(event){
      let SearchKey=  event.target.value;
      this.setState({SearchKey:SearchKey});
    }

    SearchOnClick(){
        if(this.state.SearchKey.length>=2){
            this.setState({SearchRedirectStatus:true})
        }
    }


    searchRedirect(){
        if(this.state.SearchRedirectStatus===true){
            return <Redirect to={"/ProjectListBySearch/"+this.state.SearchKey} />
        }

    }

    componentDidMount() {
      window.scroll(0,0)

      axios.get(ApiUrl.Categories).then(response=> {
        this.setState({CatData:response.data})
      }).catch(error=> {

      });

    }



  render() {

    const MyCatList=this.state.CatData;
    const MyCatView=MyCatList.map((CategoryList,i)=>{
      return  <ListGroup.Item>
                <Link className="footerLink" to={"/ProjectListByCategory/"+CategoryList.cat_name}>
                  <div>{CategoryList.cat_name}</div>
                </Link>
              </ListGroup.Item>
            });



    return (
      <Fragment>
        <Container>

            <div>
              <Form>
                <Form.Row className="align-items-center">

                  <Col xs="auto">

                    <InputGroup className="mb-5">
                      <FormControl onChange={this.SearchOnChange} id="inlineFormInputGroup" type="text" id="search" name="example" />
                      <InputGroup.Prepend>
                         <Button onClick={this.SearchOnClick} type="button" variant="primary" >
                            Search
                          </Button>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </Col>
                </Form.Row>
              </Form>
            </div>

            <Card border="light">
              <Card.Header><h6>Project Category</h6></Card.Header>
                <ListGroup variant="flush">
                  {MyCatView}
                </ListGroup>
            </Card>

{this.searchRedirect()}
        </Container>
      </Fragment>
    )
  }
}

export default Sidebar