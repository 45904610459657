import React, { Component,Fragment } from 'react';
import {Container,Row,Col,Button} from "react-bootstrap";
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import RestClient from "../../RestApi/RestClient";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';
import '../../asset/css/custom.css'
import '../../asset/css/bootstrap.min.css'
import '../../asset/css/responsive.css'

class TopBanner extends Component {

    constructor() {
    super();
    this.state={
      title:"",
      banner_img:"",
      loading:true,
      error:false
    }
  }

    componentDidMount() {
      window.scroll(0,0)

      RestClient.GetRequest(ApiUrl.Banner).then(result=>{
          if(result==null){
              this.setState({ error:true,loading:false})
          }
          else{
              this.setState({
                  title:result[0]['title'],
                  banner_img:result[0]['banner_img'],
                  loading:false
              })
          }


        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }
  render() {
    return (
      <Fragment>
        <Container fluid={true} className="topFixedBanner p-0" >
        <div className="topBannerOverlay">
	        <Row>
		       <Col>
				<div className="topContent text-center">
					<h1 className="topTitle">{this.state.title}</h1>
					<h4 className="topSubTitle">Subrata</h4>
				</div>
		       </Col>
	        </Row>
	        </div>
        </Container>
      </Fragment>
    )
  }
}

export default TopBanner