import React, { Component,Fragment } from 'react';
import {FormControl,InputGroup,ListGroup,Card,Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import ReactHtmlParser from "react-html-parser";
import RestClient from "../../RestApi/RestClient";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";

class About extends Component {
      constructor(props) {
        super();
        this.state={
            about_img:"",
            about:" ",
            mission:" ",
            vision:"",
            loading:true,
            error:false
        }
    }

    componentDidMount() {
      window.scroll(0,0)

      RestClient.GetRequest(ApiUrl.About).then(result=>{
          if(result==null){
              this.setState({ error:true,loading:false})
          }
          else{
              this.setState({
                  about_img:result[0]['about_img'],
                  about:result[0]['about'],
                  mission:result[0]['mission'],
                  vision:result[0]['vision'],
                  loading:false
              })
          }


        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }

  render() {

    return (
      <Fragment>
        <Container className="spaceBetween">
         <Row>
           <Col lg={6} md={6} sm={6} xs={12}>
              <div>
                 <img  src={ this.state.about_img } className="imgbyAbout"/>
              </div>
           </Col>

           <Col lg={6} md={6} sm={6} xs={12}>
              <div>
                 <h4 className="serviceTitle">Who We Are</h4><hr/>
                 <p className="serviceSubTitle">{ ReactHtmlParser(this.state.about) }</p>
              </div>
           </Col>
         </Row>

         <Row className="spaceBetween">
           <Col lg={12} md={12} sm={12} xs={12}>
              <div>
                <h4 className="serviceTitle">Our Mission</h4><hr/>
                <p className="serviceSubTitle">{ ReactHtmlParser(this.state.mission) }</p>
              </div>
           </Col>
         </Row>

         <Row className="spaceBetween">
           <Col lg={12} md={12} sm={12} xs={12}>
              <div>
                <h4 className="serviceTitle">Our Vision</h4><hr/>
                <p className="serviceSubTitle">{ ReactHtmlParser(this.state.vision) }</p>
              </div>
           </Col>
         </Row>

        </Container>
      </Fragment>
    )
  }
}

export default About