import React, {Component,Fragment} from 'react';
import {Card, Col, Container, Row, Button} from "react-bootstrap";
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import {Link} from "react-router-dom";
import RestClient from "../../RestApi/RestClient";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';

class Projects extends Component {

  constructor() {
    super();
    this.state={
      myData:[],
      loading:true,
      error:false
    }
  }

  componentDidMount() {
    RestClient.GetRequest(ApiUrl.Project8).then(result=>{
      if(result==null){
        this.setState({ error:true,loading:false})
      }else{
        this.setState({myData:result,loading:false})
      }
    }).catch(error=>{
      this.setState({ error:true,loading:false})
    })

  }

  render() {

    if(this.state.loading==true && this.state.error==false ){
      return  <Loading/>
    }
    else if(this.state.loading==false  && this.state.error==false)  {
      const  myList=this.state.myData;
      const myView=myList.map(myList=>{
        return <Col lg={3} md={3} sm={4} xs={12}>
                  <Card className="card text-center projectImageBox">
                    <img src={myList.img1} className="projectImgSize" />
                    <Card.Body>
                      <Link className="footerLink" to={"ProjectDetails/"+myList.id+"/"+myList.title}>
                        <Button variant="primary" size="sm" block>{myList.title}</Button>
                      </Link>
                    </Card.Body>
                  </Card>
               </Col>
          })


    return (
      <Fragment>
        <Container className="spaceBetween">
          <h2 className="mainTitle">Recent Project</h2><hr/>
          <Row>
           {myView}
          </Row>
        </Container>
      </Fragment>
    )
  }
      else if(this.state.error==true){
      return  <WentWrong/>
    }
  }
}

export default Projects