import React, { Component,Fragment } from 'react';
import {Form,FormControl,InputGroup,ListGroup,Card,Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import ReactHtmlParser from "react-html-parser";
import RestClient from "../../RestApi/RestClient";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import Sidebar from "../Sidebar/Sidebar";
import CommentSend from "../Review/CommentSend";

class ProjectDetails extends Component {
      constructor(props) {
        super();
        this.state={
            MyProjectID:props.id,
            MyProjectTitle:props.projectName,
            RelatedProject:[],
            GetComments:[],
            CountComments:"",
            title:"",
            subtitle:" ",
            details:" ",
            img1:"",
            category_name:"",
            loading:true,
            error:false

        }
    }

    componentDidMount() {
      window.scroll(0,0)

      RestClient.GetRequest(ApiUrl.ProjectDetails+this.state.MyProjectID).then(response=>{
          if(response==null){
              this.setState({ error:true,loading:false})
          }
          else{
              this.setState({
                  title:response.project[0]['title'],
                  subtitle:response.project[0]['subtitle'],
                  details:response.project[0]['details'],
                  img1:response.project[0]['img1'],
                  category_name:response.project[0]['category_name'],
                  RelatedProject:response.relatedProject,
                  loading:false
              })
          }


        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })


      RestClient.GetRequest(ApiUrl.GetComments+this.state.MyProjectID).then(response=>{
          if(response==null){
              this.setState({ error:true,loading:false,})
          }
          else{
              this.setState({
                  GetComments:response.commentstList,
                  CountComments:response.count,
                  loading:false,
              })
          }


        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }


    sendSearchData(){
      let searchData=  document.getElementById("search").value;
      let jsonObject={searchData:searchData};
      RestClient.PostRequest(ApiUrl.Search,JSON.stringify(jsonObject)).then(result=>{
      alert(result);
      }).catch(error=>{
          alert("Error");
      })

    }


  render() {
    const GetCommentsList=this.state.GetComments;
    let CountComments=this.state.CountComments;

    const RelatedProjectList=this.state.RelatedProject;
    const RelatedProjectListView=RelatedProjectList.map((RelatedProList,i)=>{
      return  <ListGroup.Item>
                  <Row>
                    <Col lg={4} md={4} sm={12} xs={12}>
                        <img  src={RelatedProList.img2} className="imgByRelPro"/>
                    </Col>
                    <Col lg={8} md={8} sm={12} xs={12}  className="p-0">
                        <Link className="footerLink" to={"/ProjectDetails/"+RelatedProList.id+"/"+RelatedProList.title}>
                          <div>{RelatedProList.title}</div>
                        </Link>
                    </Col>
                  </Row>
                </ListGroup.Item>
            });


    const CommentsListView=GetCommentsList.map((CommentsList,i)=>{
      return  <Row  className="mt-2">
                <Col lg={1} md={4} sm={12} xs={12}>
                    <img  src="https://devapi.webhunter24.com/storage/project/milFScx1RrkIoAFiM3UI72fxXQNLuXrGXlASdbdh.jpeg" className="imgByRelPro"/>
                </Col>
                <Col lg={4} md={8} sm={12} xs={12}>
                    <h6>{CommentsList.name}</h6>
                    <p className="desbyCat">{CommentsList.message}</p>
                </Col>
              </Row>
            });
          

if(CountComments){
    return (
      <Fragment>
        <Container>
          <Row className="spaceBetween">

            <Col lg={9} md={8} sm={8} xs={12}>
              <Card>
                <Card.Img variant="top" src={this.state.img1} className="projectDetailsImg"/>
                <Card.Body>
                  <h6 className="serviceTitle">{this.state.title}</h6>
                    {ReactHtmlParser(this.state.details)}
                </Card.Body>
              </Card>

              <Container className="mt-3">
                <h4 serviceTitle>Comments({this.state.CountComments})</h4><hr/>
                <Card>
                   {CommentsListView}
                </Card>
              </Container>
            </Col>


            <Col lg={3} md={4} sm={4} xs={12}>
              <Sidebar/>

              <Card border="light" className="mt-3">
                <Card.Header><h6>Related Project</h6></Card.Header>
                  <ListGroup variant="flush">
                    {RelatedProjectListView}
                  </ListGroup>
              </Card>
            </Col>

          </Row>

         <Row className="spaceBetween">
           <Col lg={9} md={9} sm={9} xs={12}>
              <h4 serviceTitle>Put Your Comments</h4><hr/>

               <CommentSend  ProjectID={this.state.MyProjectID} ProjectName={this.state.MyProjectTitle}/>

           </Col>
         </Row>

        </Container>
      </Fragment>
    )
  }else{
        return (
      <Fragment>
        <Container>
          <Row className="spaceBetween">

            <Col lg={9} md={8} sm={8} xs={12}>
              <Card>
                <Card.Img variant="top" src={this.state.img1} className="projectDetailsImg"/>
                <Card.Body>
                  <h6 className="serviceTitle">{this.state.title}</h6>
                    {ReactHtmlParser(this.state.details)}
                </Card.Body>
              </Card>
            </Col>


            <Col lg={3} md={4} sm={4} xs={12}>
              <Sidebar/>

              <Card border="light" className="mt-3">
                <Card.Header><h6>Related Project</h6></Card.Header>
                  <ListGroup variant="flush">
                    {RelatedProjectListView}
                  </ListGroup>
              </Card>
            </Col>

          </Row>

         <Row className="spaceBetween">
           <Col lg={9} md={9} sm={9} xs={12}>
              <h4 serviceTitle>Put Your Comments</h4><hr/>

               <CommentSend  ProjectID={this.state.MyProjectID} ProjectName={this.state.MyProjectTitle}/>

           </Col>
         </Row>

        </Container>
      </Fragment>
    )
  }
  }
}

export default ProjectDetails