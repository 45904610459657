import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import SliderHome from "../components/SliderHome/SliderHome";
import Services from "../components/Services/Services";
import Projects from "../components/Projects/Projects";
import Footer from "../components/Footer/Footer";
import Review from "../components/Review/Review";

class HomePage extends Component {
    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation/>
				<SliderHome/>
				<Services/>
				<Projects/>
                <Review/>
				<Footer/>
            </Fragment>
        );
    }
}

export default HomePage;