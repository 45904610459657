import React, {Component, Fragment} from 'react';
import {CardDeck,Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";

class ProjectListBySearch extends Component {

    constructor(props){
      super();
        this.state={
            SearchKey:props.SearchKey,
            ProjectData:[],
            CountProject:"",
            loading:true,
            error:false
        }

    }


    componentDidMount() {
        window.scroll(0,0)
        axios.get(ApiUrl.ProjectListBySearch(this.state.SearchKey)).then(response=> {
           if(response==null){
              this.setState({ error:true,loading:false})
          }
           else {
            this.setState({
              ProjectData:response.data.ProjectList,
              CountProject:response.data.count,
              loading:false
            })
        }

        }).catch(error=>{
          this.setState({ error:true,loading:false})
      })

    }


    render() {

      const CountProject=this.state.CountProject;

    if(this.state.loading==true && this.state.error==false ){
        return  <Loading/>
    }
    else if(this.state.loading==false  && this.state.error==false)  {
        
        const MyList=this.state.ProjectData;
        const myView=MyList.map(ProjectList=>{
            return <Col lg={3} md={3} sm={3} sm={3} xs={12} className="p-2">
                    <CardDeck>
                      <Card className="card text-center projectImageBox">
                        <Card.Img src={ProjectList.img1} className="projectImgSize"/>
                        <Card.Body>
                          <Link className="footerLink" to={"/ProjectDetails/"+ProjectList.id+"/"+ProjectList.title}>
                            <Button variant="primary" block>{ProjectList.title}</Button>
                          </Link>
                        </Card.Body>
                      </Card>
                    </CardDeck>
                   </Col>
            })

        return (
            <Fragment>
                <Container className="text-center spaceBetween">
                <h2 className="mainTitle">{this.state.CountProject} Projects Found</h2>
                    <Row>
                        {myView}
                    </Row>
                </Container>
            </Fragment>
       );
        }
        else if(this.state.error==true){
            return  <WentWrong/>
        }

    }
}

export default ProjectListBySearch;