import React, {Component,Fragment} from 'react';
import TopNavigation from "../components/TopNavigation/TopNavigation";
import Banner from "../components/TopBanner/TopBanner";
import AllProject from "../components/Projects/AllProjects";
import Footer from "../components/Footer/Footer";

class AllProjectPage extends Component {

    componentDidMount() {
        window.scroll(0,0)
    }

    render() {
        return (
            <Fragment>
                <TopNavigation/>
                <Banner/>
                <AllProject/>
                <Footer/>
            </Fragment>
        );
    }
}

export default AllProjectPage;