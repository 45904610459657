import React, { Component,Fragment } from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";
import RestClient from "../../RestApi/RestClient";
import ApiUrl from "../../RestApi/AppUrl";
import axios from 'axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class SliderHome extends Component {

    constructor() {
    super();
    this.state={
      myData:[],
      loading:true,
      error:false
    }
  }

  componentDidMount() {
    RestClient.GetRequest(ApiUrl.Slider).then(result=>{
      if(result==null){
        this.setState({ error:true,loading:false})
      }else{
        this.setState({myData:result,loading:false})
      }
    }).catch(error=>{
      this.setState({ error:true,loading:false})
    })

  }

  render() {

      const settings = {
      dots: true,
      infinite: true,
      autoplay:true,
      autoplaySpeed:3000,
      speed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1
  };


  if(this.state.loading==true && this.state.error==false ){
      return  <Loading/>
    }
    else if(this.state.loading==false  && this.state.error==false)  {
      const  myList=this.state.myData;
      const myView=myList.map(myList=>{
        return  <div>
                 <img src={myList.slider_img} className="homeSliderImg"/>
                </div>
              })

    return (
        <Fragment>
          <Container fluid={true}>
            <Slider {...settings}>

              {myView}

            </Slider>
          </Container>
        </Fragment>
    )
  }
      else if(this.state.error==true){
      return  <WentWrong/>
    }
  }
}

export default SliderHome