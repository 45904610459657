import React, { Component,Fragment } from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import sliderImg1 from "../../asset/images/home-banner4.jpg";
import sliderImg2 from "../../asset/images/slider2.jpg";
import sliderImg3 from "../../asset/images/slider3.jpeg";
import sliderImg4 from "../../asset/images/slider3.jpeg";
import sliderImg5 from "../../asset/images/slider3.jpeg";
import sliderImg6 from "../../asset/images/slider3.jpeg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Review extends Component {

  render() {

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplaySpeed:3000,
            autoplay:true,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };






    return (
        <Container className="spaceBetween">
          <h2 className="mainTitle">Client Review</h2><hr/>
            <Slider {...settings}>


<div className="p-1">
<Card>
    <img src={sliderImg1} className="sliderReviewImg" />
    <Card.Body>
        <h5 serviceTitle>Subrata Dey</h5>
        <p className="serviceSubTitle">Some comments will go here.its a very nice project</p>
    </Card.Body>
</Card>
</div>

<div className="p-1">
<Card>
    <img src={sliderImg1} className="sliderReviewImg" />
    <Card.Body>
        <h5 serviceTitle>Subrata Dey</h5>
        <p className="serviceSubTitle">Some comments will go here.its a very nice project</p>
    </Card.Body>
</Card>
</div>

<div className="p-1">
<Card>
    <img src={sliderImg1} className="sliderReviewImg" />
    <Card.Body>
        <h5 serviceTitle>Subrata Dey</h5>
        <p className="serviceSubTitle">Some comments will go here.its a very nice project</p>
    </Card.Body>
</Card>
</div>

<div className="p-1">
<Card>
    <img src={sliderImg1} className="sliderReviewImg" />
    <Card.Body>
        <h5 serviceTitle>Subrata Dey</h5>
        <p className="serviceSubTitle">Some comments will go here.its a very nice project</p>
    </Card.Body>
</Card>
</div>

<div className="p-1">
<Card>
    <img src={sliderImg1} className="sliderReviewImg" />
    <Card.Body>
        <h5 serviceTitle>Subrata Dey</h5>
        <p className="serviceSubTitle">Some comments will go here.its a very nice project</p>
    </Card.Body>
</Card>
</div>

<div className="p-1">
<Card>
    <img src={sliderImg1} className="sliderReviewImg" />
    <Card.Body>
        <h5 serviceTitle>Subrata Dey</h5>
        <p className="serviceSubTitle">Some comments will go here.its a very nice project</p>
    </Card.Body>
</Card>
</div>

            </Slider>
        </Container>
    )
  }
}

export default Review