import React, {Component, Fragment} from 'react';
import {CardDeck,Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import ReactHtmlParser from "react-html-parser";
import RestClient from "../../RestApi/RestClient";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";

class AllProjects extends Component {
    constructor() {
        super();
        this.state={
            myData:[],
            loading:true,
            error:false
        }
    }


    componentDidMount() {

        RestClient.GetRequest(ApiUrl.ProjectAll).then(result=>{
            if(result==null){
                this.setState({ error:true,loading:false})
            }
            else {
                this.setState({myData:result,loading:false})
            }

        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }

    render() {
        if(this.state.loading==true && this.state.error==false ){
            return  <Loading/>
        }
        else if(this.state.loading==false  && this.state.error==false)  {
        const  myList=this.state.myData;
        const myView=myList.map(myList=>{
            return <Col lg={3} md={3} sm={3} sm={3} xs={12} className="p-2">
<CardDeck>
  <Card className="card text-center projectImageBox">
    <Card.Img src={myList.img1} className="projectImgSize"/>
    <Card.Body>
      <Link className="footerLink" to={"ProjectDetails/"+myList.id+"/"+myList.title}>
        <Button variant="primary" block>{myList.title}</Button>
      </Link>
    </Card.Body>
  </Card>
</CardDeck>
            </Col>
        })

        return (
            <Fragment>
                <Container className="text-center spaceBetween">
                <h2 className="mainTitle">Our Projects Gallery</h2>
                    <Row>
                        {myView}
                    </Row>
                </Container>
            </Fragment>
        );
        }
        else if(this.state.error==true){
            return  <WentWrong/>
        }

    }
}

export default AllProjects;