import React, { Component,Fragment } from 'react';
import {FormControl,InputGroup,ListGroup,Card,Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ApiUrl from "../../RestApi/AppUrl";
import ReactHtmlParser from "react-html-parser";
import RestClient from "../../RestApi/RestClient";
import axios from 'axios';
import Loading from "../Loading/Loading";
import WentWrong from "../WentWrong/WentWrong";

class Term extends Component {
      constructor(props) {
        super();
        this.state={
            terms:"",
            loading:true,
            error:false
        }
    }

    componentDidMount() {
      window.scroll(0,0)

      RestClient.GetRequest(ApiUrl.SettingData).then(result=>{
          if(result==null){
              this.setState({ error:true,loading:false})
          }
          else{
              this.setState({
                  terms:result[0]['terms'],
                  loading:false
              })
          }


        }).catch(error=>{
            this.setState({ error:true,loading:false})
        })
    }

  render() {

    return (
      <Fragment>
        <Container className="spaceBetween">
         <Row>
           <Col lg={12} md={12} sm={12} xs={12}>
              <div>
                <h4 className="serviceTitle">Refund Policy</h4><hr/>
                <p className="serviceSubTitle">{ ReactHtmlParser(this.state.terms) }</p>
              </div>
           </Col>
         </Row>
        </Container>
      </Fragment>
    )
  }
}

export default Term